export default [
  {
    id: 'first_inner',
    tabName: '工单管理',
    icon: 'feat_icon1.png',
    children: [
      {
        title: '自助报修',
        text: [
          '实现医院维修商多方式报修，一物一码，扫码即可立即报修，医院与供应商便捷沟通无障碍，快速响应，确保客户问题第一时间得到反馈。',
        ],
        imgUrl: 'tab1_1.png',
      },
      {
        title: '工单自定义',
        isHot: true,
        text: [
          '您可根据需求创建自定义工单类型，图形化配置工单属性字段，并可根据需要启用工时管理、备件管理等附件功能组件，满足多样化的业务需求。',
        ],
        imgUrl: 'tab1_2.png',
      },
      {
        title: '实时监控',
        text: ['电脑端、手机端均可实时掌握服务进度，配合及时的短信、消息通知，不漏掉任何一项服务动态。'],
        imgUrl: 'tab1_3.png',
      },
    ],
  },
  {
    id: 'second_inner',
    tabName: '客户管理',
    icon: 'feat_icon2.png',
    children: [
      {
        title: '客户信息',
        text: [
          '实现客户信息一键管理，轻松维护客户地址、联系人、设备信息、工单记录等信息，帮助您建立属于自己的客户数据库，支持在线更改、存档等。',
        ],
        imgUrl: 'tab2_1.png',
      },
      {
        title: '合同管理',
        isHot: true,
        text: [
          '助力企业规范合同流程，实现合同更加信息化，流程更加标准化，审批更加智能化，节点更加清晰化，人员更加便利化。',
        ],
        imgUrl: 'tab2_2.png',
      },
      {
        title: '产品管理',
        text: [
          '创建自身专属的服务产品库，基于自定义结构平台，可针对不同维度创建独立的产品档案，整合产品、服务、备件、合同等各类业务要素。',
        ],
        imgUrl: 'tab2_3.png',
      },
    ],
  },
  {
    id: 'three_inner',
    tabName: '设备管理',
    icon: 'feat_icon3.png',
    children: [
      {
        title: '设备档案',
        text: [
          '平台自带超十万条设备知识库，能够有效帮助设备资产快速记录登记，方便管理设备多项信息，帮助企业更好的管理设备全生命周期，为企业建立属于自己的设备库。',
        ],
        imgUrl: 'tab3_1.png',
      },
      {
        title: '设备二维码',
        isHot: true,
        text: [
          '每个设备都能生成自己的专属二维码，扫码即可快速发起工单，帮助设备使用者在最短的时间启动报修流程，加快工单流转速度，提升设备售后效率。',
        ],
        imgUrl: 'tab3_2.png',
      },
      {
        title: '设备自定义',
        text: [
          '通过医修保自定义业务平台，可根据需求配置多种设备类型，针对每种设备类型设定独立的档案结构，满足更多业务需要。',
        ],
        imgUrl: 'tab3_3.png',
      },
    ],
  },
  {
    id: 'four_inner',
    tabName: '备件管理',
    icon: 'feat_icon4.png',
    children: [
      {
        title: '备件库管理',
        text: [
          '实现备件统一管理，实时动态信息查看，帮助企业实现备件申、领、收、退等多状态规范化、数字化、透明化的目的。',
        ],
        imgUrl: 'tab4_1.png',
      },
      {
        title: '个人备件库',
        isHot: true,
        text: [
          '通过个人备件库的申领，帮助工程师及库管人员减少备件申领、调拨过程中的阻碍，提升备件使用的时效性，帮助工程师团队更好的服务于客户。',
        ],
        imgUrl: 'tab4_2.png',
      },
      {
        title: '出入库管理',
        text: [
          '建立完善的备件库存管理系统，通过记录的出入库信息盘点，动态监控各种备件实时的数量，及时预警，并生成完整的出入库记录。',
        ],
        imgUrl: 'tab4_3.png',
      },
    ],
  },
  {
    id: 'five_inner',
    tabName: '自定义配置',
    icon: 'feat_icon5.png',
    children: [
      {
        title: '表单自定义',
        isHot: true,
        text: [
          '可根据自身业务需求，自主创建多种业务类型模板，帮助企业定制化生成对应的业务模块，帮助提升服务专业性和客户针对性。',
        ],
        imgUrl: 'tab5_1.png',
      },
      {
        title: '流程自定义',
        text: [
          '根据服务场景需求，自定义调整流程节点，更加灵活、合理的配置不同类型的服务流程，更好的规范工程师的服务及提升客户的满意度。',
        ],
        imgUrl: 'tab5_2.png',
      },
      {
        title: '业务组件自定义',
        isHot: true,
        text: [
          '用户可以根据实际的业务场景，定义不同类型的业务，通过不同的业务组件来记录过程中的各种个性化信息，并可将业务组件关联至客户、设备、工单等多种业务场景。',
        ],
        imgUrl: 'tab5_3.png',
      },
    ],
  },
  {
    id: 'six_inner',
    tabName: 'Iot',
    icon: 'feat_icon6.png',
    children: [
      {
        title: '设备定位',
        text: ['通过地图形式即可在线查看设备分布情况，数据情况，异常情况等，远程实现资源规划及设备管理。'],
        imgUrl: 'tab6_1.png',
      },
      {
        title: '数据采集',
        text: [
          '快速对设备地理分布、工作数据、故障信息，服务效率等数据进行采集、及时性生成数据分析，后台大屏清晰展示。',
        ],
        imgUrl: 'tab6_2.png',
      },
      {
        title: '运行分析',
        isHot: true,
        text: [
          '系统自动根据数据做出监测数据表盘，通过图表、列表的颜色，甄别设备的运行状态、当设备出现故障触发预警，自动生成预测性工单，大幅度降低运行过程中的故障率。',
        ],
        imgUrl: 'tab6_3.png',
      },
    ],
  },
];
