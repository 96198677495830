<template>
  <div class="footer_feature_container">
    <div class="footer_feature">
      <div class="nav_box left">
        <div class="nav_title">功能介绍</div>
        <div class="nav_ul">
          <div v-for="item in navListJson" :key="item.id" class="nav_li" @click="goFeatures(item.id)">{{item.tabName}}</div>
        </div>
      </div>
      <div class="nav_box center">
        <div class="nav_title">产品解决方案</div>
        <div class="nav_ul">
          <div @click="goHome" class="nav_li">客户快速报修</div>
          <div @click="goHome" class="nav_li">服务流程</div>
          <div @click="goHome" class="nav_li">服务档案</div>
          <div @click="goHome" class="nav_li">备件管理</div>
          <div @click="goHome" class="nav_li">自定义精细管理</div>
          <div @click="goHome" class="nav_li">聚合数据大屏</div>
        </div>
      </div>
      <div class="nav_box right">
        <div class="nav_title">联系我们</div>
        <div class="about_box">
          <div class="code_box">
            <img :src="require('@/assets/images/pcHome/registerQR.jpg')" />
            <p>扫码注册</p>
          </div>
          <div class="desc_box">
            <div class="desc_li">
              <div class="label">合作咨询：</div>
              <div class="text">xzkefu@xiaoziyixue.cn</div>
            </div>
            <div class="desc_li">
              <div class="label">咨询热线：</div>
              <div class="text">021-5281 3076</div>
            </div>
            <div class="desc_li">
              <div class="label">上海运营中心：</div>
              <div class="text">上海市普陀区怒江北路449弄A区3号楼</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FEATURES_DESC_JSON from './featuresDesc.js';
const navListJson = FEATURES_DESC_JSON.map(i => ({
  id: i.id,
  tabName: i.tabName,
}));
export default {
  props: {
    isHome: {
      type: Boolean,
      default: false,
    },
    sourcePage: {
      type: String,
    },
  },
  data () {
    return {
      navListJson,
    };
  },
  methods: {
    goHome () {
      if (this.isHome) {
        this.goAnchor('#productSolutions');
      } else {
        this.$router.push({
          name: 'Home',
          query: {
            id: '#productSolutions',
          },
        });
      }
    },
    goAnchor (id) {
      this.$nextTick(() => {
        document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      });
    },
    goFeatures (tabName) {
      if (this.sourcePage === 'about') {
        this.$router.push({
          name: 'Home',
          query: {
            id: '#features',
            tabName: tabName,
          },
        });
      } else {
        this.$emit('change-tab', tabName, this.sourcePage);
        this.goAnchor('#features');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.footer_feature_container {
  background: rgba(0, 60, 117, 1);
  padding: 60px 0;
  .footer_feature {
    color: #ffffff;
    width: 1200px;
    display: flex;
    margin: auto;
    min-height: 200px;
    min-height: 200px;
    display: flex;
    .left {
      width: 300px;
      flex-shrink: 0;
    }
    .center {
      width: 340px;
      flex-shrink: 0;
    }
    .right {
      flex-grow: 1;
    }
    .nav_box {
      .nav_title {
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        line-height: 16px;
      }
      .nav_li {
        cursor: pointer;
        margin-top: 24px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 14px;
      }
      .about_box {
        margin-top: 24px;
        display: flex;
        .code_box {
          width: 116px;
          flex-shrink: 0;
          img {
            display: block;
            width: 116px;
            height: 116px;
            margin-bottom: 15px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
            line-height: 20px;
          }
        }
        .desc_box {
          padding-left: 26px;
          .desc_li {
            display: flex;
            margin-bottom: 12px;
            .label {
              font-size: 16px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.8);
              line-height: 22px;
              letter-spacing: 1px;
              flex-wrap: nowrap;
            }
            .text {
              font-size: 16px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.8);
              line-height: 22px;
              letter-spacing: 1px;
              flex: 1;
            }
          }
        }
      }
    }
  }
}
</style>
