<template>
  <div class="header_box">
    <div class="menu_box">
      <div class="logo_box">
        <img :src="require('../../assets/images/pcHome/logo.png')" />
      </div>
      <nav v-if="isPC" class="menu_list isPc" id="menu_ul">
        <div
          v-for="(navItem, index) in NavDatas"
          :key="index"
          :class="['menu_item', activeIndex === index ? 'active' : '']"
          @click="activeNavHandle(navItem, index)"
        >
          {{ navItem.name }}
          <div v-if="index === 1" class="features_nav_box">
            <div class="features_ul">
              <div v-for="item in FEATURES_DESC" :key="item.id" class="feat_li">
                <div @click.stop="goFeatTarget(item.id)" class="feat_title">{{ item.tabName }}</div>
                <div @click.stop="goFeatTarget(item.id)" class="feat_span" v-for="(i, ind) in item.children" :key="ind">
                  <span class="text">
                    {{ i.title }}
                  </span>
                  <img v-if="i.isHot" :src="require('@/assets/images/about/hot.png')" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="menu_item" @click="goXiaozi">小紫官网</a>
      </nav>
      <div v-if="isPC" class="login_btn_group">
        <div class="nav_btn login_btn" @click="goMaintenance">登录</div>
        <div class="nav_btn register_btn" @click="goMaintenance('regis')">立即注册</div>
      </div>
      <div v-else class="mob_icon" @click="openDrawerHandle">
        <i :class="[drawerFlag ? 'el-icon-close' : 'el-icon-s-fold', 'el-icon']"></i>
      </div>
    </div>
    <el-drawer
      v-if="!isPC"
      class="drawerBox"
      title=""
      :visible.sync="drawerFlag"
      direction="rtl"
      size="235px"
      append-to-body
      :show-close="true"
    >
      <div class="draw_body">
        <div class="draw_content">
          <div class="nav_text">导航</div>
          <a class="menu_item" @click="goHome('#home')">首页</a>
          <el-menu class="menu_item">
            <el-submenu index="1">
              <template slot="title">
                <span slot="title">功能介绍</span>
              </template>
              <el-menu-item v-for="item in FEATURES_DESC" :key="item.id" @click="goTarget(item.id)">
                {{ item.tabName }}
              </el-menu-item>
            </el-submenu>
          </el-menu>
          <a class="menu_item" @click="goXiaozi">小紫官网</a>
          <!--
          <a class="menu_item" @click="goHome('#productSolutions')">产品解决方案</a>
          <a class="menu_item" @click="goAbout">关于我们</a>-->
        </div>
        <div class="reg_btn" @click="gotoRegi()">申请注册</div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import FEATURES_DESC_JSON from './featuresDesc.js';
let FEATURES_DESC = cloneDeep(FEATURES_DESC_JSON);

const NavDatas = [
  {
    name: '首页',
    id: '#home',
    pathName: 'Home',
  },
  {
    name: '功能介绍',
    pathName: 'HOME_FEATURES',
  },
  /*
  {
    name: '产品解决方案',
    id: '#productSolutions',
    pathName: 'Home',
  },
  {
    name: '关于我们',
    pathName: 'HOME_ABOUT_US',
  },
  */
];

export default {
  props: {
    isPC: {
      type: Boolean,
      required: true,
    },
    showGoRegi: {
      type: Boolean,
      default: true,
    },
    scrollOptical: {
      required: true,
      type: Number,
    },
    sourceType: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      NavDatas,
      activeIndex: 10,
      FEATURES_DESC,
      drawerFlag: false,
    };
  },
  methods: {
    goXiaozi () {
      window.open(process.env.VUE_APP_XZ_URL, '_blank');
    },
    goAbout () {
      this.$router.push({ name: 'HOME_ABOUT_US' }).then(() => {
        this.drawerFlag = false;
      });
    },
    activeNavHandle (navData, index) {
      this.resetScrollTop();
      if (index === 0 || index === 2) {
        this.goHome(navData.id);
      }
      if (index === 1 || index === 3) {
        this.$router.push({
          name: navData.pathName,
        });
      }
      this.activeIndex = index;
    },
    resetScrollTop () {
      if (document.documentElement.scrollTop) document.documentElement.scrollTop = 0;
      if (document.body.scrollTop) document.body.scrollTop = 0;
    },
    resetActive (index) {
      this.activeIndex = index;
    },
    goFeatTarget (tabName) {
      // 判断是否是功能页，非功能页先完成跳转，下方goHome同理
      if (this.$route.name === 'HOME_FEATURES') {
        this.$emit('go-position', tabName);
      } else {
        this.$router.push({
          name: 'HOME_FEATURES',
          query: {
            tabName: tabName,
          },
        });
      }
    },
    goTarget (tabName) {
      // 非home页跳转home，然后定位
      if (this.$route.name !== 'Home') {
        this.resetScrollTop();
        this.$router.push({
          name: 'Home',
          query: {
            tabName: tabName,
            id: '#features',
          },
        });
      } else {
        this.goAnchor('#features');
      }
    },
    openDrawerHandle () {
      this.drawerFlag = !this.drawerFlag;
    },
    gotoRegi () {
      window.open(`${process.env.VUE_APP_WEAB_URL}xiaozi-xmb/wireless/open/miniapp/redirectUrlLinkForLogin`, '_blank');
      this.drawerFlag = false;
    },
    goHome (id) {
      if (this.$route.name === 'Home') {
        this.goAnchor(id);
      } else {
        this.$router.push({
          name: 'Home',
          query: {
            id,
          },
        });
      }
    },
    goAnchor (id) {
      this.$emit('goAnchor', id);
      this.drawerFlag = false;
    },
    goMaintenance (type) {
      const urlG = type === 'regis' ? `${process.env.VUE_APP_LOGIN_URL}?type=regis` : process.env.VUE_APP_LOGIN_URL;
      window.open(urlG, '_blank');
    },
  },
  watch: {
    drawerFlag: {
      immediate: true,
      handler (val) {
        this.$emit('closeVideo', val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
$mobileW: 1200px;
@media (min-width: $mobileW) {
  .header_box {
    position: fixed;
    width: 100%;
    height: 64px;
    z-index: 100;
    top: 0;
    background: rgba(255, 255, 255, 0.7);
    .menu_box {
      position: relative;
      line-height: 64px;
      height: 64px;
      .logo_box {
        position: relative;
        float: left;
        left: 24px;
        top: 12px;
        width: 142px;
        height: 42px;
        img {
          display: block;
          height: 100%;
        }
      }
      .menu_list.isPc {
        left: 10.4%;
        position: relative;
        float: left;
        width: auto;
        .menu_item {
          position: relative;
          flex-shrink: 0;
          cursor: pointer;
          float: left;
          padding: 0 20px;
          margin-right: 12px;
          height: 64px;
          line-height: 64px;
          font-size: 14px;
          color: rgba(36, 40, 50, 0.9);
          text-align: center;
          &.active {
            color: #0f89ff;
          }
          .features_nav_box {
            color: rgba(36, 40, 50, 0.7);
            text-align: left;
            display: none;
            position: absolute;
            left: -100px;
            background: #ffffff;
            padding: 10px 32px 16px;
            min-width: 1024px;
            .features_ul {
              display: flex;
              font-size: 14px;
              .feat_li {
                flex: 1;
                flex-shrink: 0;
                width: 160px;
                .feat_title {
                  color: #0f89ff;
                  height: 46px;
                  padding-bottom: 10px;
                  border-bottom: 1px solid rgba(36, 40, 50, 0.16);
                }
                .feat_span {
                  height: 46px;
                  .text {
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                  }
                  img {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    width: 46px;
                  }
                  &:hover {
                    color: rgba(15, 137, 255, 0.7);
                  }
                }
              }
            }
          }
          &:hover {
            color: #0f89ff;
            .features_nav_box {
              display: block;
            }
          }
        }
      }
      .login_btn_group {
        float: right;
        .nav_btn {
          display: inline-block;
          vertical-align: top;
          text-align: center;
          cursor: pointer;
          height: 64px;
          line-height: 64px;
          font-size: 14px;
        }
        .login_btn {
          width: 92px;
          color: rgba(6, 24, 48, 0.8);
        }
        .register_btn {
          width: 120px;
          background: #ed4a4a;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}
@media (max-width: $mobileW) {
  .header_box {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 56px;
    overflow: hidden;
    background: rgba(255, 255, 255, 1);
    .menu_box {
      position: relative;
      height: 56px;
      .logo_box {
        position: relative;
        float: left;
        left: 16px;
        top: 12px;
        width: 106px;
        height: 32px;
        img {
          display: block;
          height: 100%;
        }
      }
      .mob_icon {
        float: right;
        position: absolute;
        right: 16px;
        top: 16px;
        .el-icon {
          font-size: 24px;
          color: #666;
        }
      }
    }
  }
  .drawerBox {
    .draw_body {
      position: relative;
      height: 100%;
      min-height: 350px;
      .draw_content {
        height: calc(100% - 60px);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 4px 12px 0 12px;
        .nav_text {
          padding-left: 4px;
          margin-bottom: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
        .menu_item {
          // position: relative;
          font-weight: 400;
          display: block;
          text-decoration-line: none;
          font-size: 16px;
          color: #242832;
          text-indent: 5px;
          height: 45px;
          line-height: 45px;
          padding-left: 10px;
          margin-bottom: 12px;
          &.active {
            color: #0f89ff;
          }

          .el-menu-item:hover {
            background: transparent;
          }
          &.el-menu {
            height: auto;
          }
        }
      }
      .reg_btn {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #0f89ff;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #ffffff;
        font-size: 16px;
      }
    }
  }
  /deep/ .el-menu {
    border-right: none;
    .el-submenu__title {
      height: 46px;
      line-height: 46px;
      padding: 0;
      background: transparent;
      text-indent: -14px;
      font-size: 16px;
      font-weight: 400;
      color: #242832;
      &:hover {
        background: transparent;
      }
    }
    .el-menu-item {
      text-indent: -20px;
      font-size: 16px;
      color: rgba(36, 40, 50, 0.6);
    }
  }
}
</style>
